<template>
  <div>
    <v-page id="omgeving" background-color="#FFF" class="py-16 d-flex text-center" no-auto-height justify="center" align="center" >
      <v-container>
        <v-row>
          <v-col class="font-weight-bold" style="font-size: 1rem;">{{ $t("omgeving.header") }}</v-col>
        </v-row>
        <v-row>
          <v-col><h1 class="dz c-primary" style="font-size: 4rem;">{{ $t("omgeving.intro-header") }}</h1></v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="12" lg="8" xl="6">
            {{ $t("omgeving.intro") }}
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <v-page id="omgeving-blocks" background-color="#91B6BA" class="py-16 text-center" no-auto-height :background="require('@/assets/watermerk.svg?fill=#fff')" style="background-size: 100%;background-position:center;">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="4" class="d-flex flex-grow-1">
            <room-card :src="require('@/assets/133.jpg')" :header-text="$t('omgeving.geschiedenis-header')" :to="{name: 'Geschidenis', params: {locale: $i18n.locale}}">
              {{ $t("omgeving.geschiedenis") }}
            </room-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="d-flex flex-grow-1">
            <room-card :src="require('@/assets/zonnebaden of wandelen.jpg')" :header-text="$t('omgeving.natuurstrand-header')" :to="{name: 'Natuur', params: {locale: $i18n.locale}}">
              {{ $t("omgeving.natuurstrand") }}
            </room-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="d-flex flex-grow-1">
            <room-card :src="require('@/assets/volop eten en drinken.jpg')" :header-text="$t('omgeving.shoppengenieten-header')" :to="{name: 'Shoppen', params: {locale: $i18n.locale}}">
              {{ $t("omgeving.shoppengenieten") }}
            </room-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="d-flex flex-grow-1">
            <room-card :src="require('@/assets/rereatie op en om het water.jpg')" :header-text="$t('omgeving.sportrecreatie-header')" :to="{name: 'Sport', params: {locale: $i18n.locale}}">
              {{ $t("omgeving.sportrecreatie") }}
            </room-card>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import VPage from "@/components/VPage";
import Footer from "@/views/Footer";
import RoomCard from "@/components/RoomCard";
export default {
name: "Omgeving.vue",
  components: {VPage, Footer, RoomCard}
}
</script>

<style scoped>

</style>