<template>
  <div>
    <v-page background-color="#FFF" :background="require('@/assets/watermerk.svg?fill=#F7F7F7')" style="background-size: 100%;background-position:center;" class="py-16" no-auto-height>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/rereatie op en om het water.jpg')"></v-img>
          </v-col>
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 4rem;">{{ $t("sport.recreatie-header") }}</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">{{ $t("sport.recreatie") }}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <v-page background-color="#282828" style="background-size: 100%;background-position:center;" class="py-16" no-auto-height dark>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 4rem;">{{ $t("sport.golfbanen-header") }}</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left" v-linkified>
                {{ $t("sport.golfbanen") }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/een van de oudste golfbanen.jpg')"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <v-page background-color="#91B6BA" :background="require('@/assets/watermerk.svg?fill=#FFFFFF')" style="background-size: 100%;background-position:center;" class="py-16" no-auto-height>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/surfen en duiken.jpg')"></v-img>
          </v-col>
          <v-col>
            <v-row>
              <v-col><h1 class="dz" style="font-size: 4rem;">{{ $t("sport.surfen-header") }}</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">
                {{ $t("sport.surfen") }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import VPage from "@/components/VPage";
import Footer from "@/views/Footer";

export default {
  name: "Sport",
  components: {VPage, Footer}
}
</script>

<style scoped>

</style>