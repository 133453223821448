<template>
  <div>
    <v-page background-color="#FFF" :background="require('@/assets/watermerk.svg?fill=#F7F7F7')" style="background-size: 100%;background-position:center;" class="py-16" no-auto-height>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/135.jpg')" :aspect-ratio="4/3"></v-img>
          </v-col>
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">{{ $t("geschiedenis.tromp-header") }}</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">{{ $t("geschiedenis.tromp") }}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <v-page background-color="#282828" style="background-size: 100%;background-position:center;" class="py-16" no-auto-height dark>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col>
            <v-row>
              <v-col><h1 class="dz c-primary" style="font-size: 3rem;">{{ $t("geschiedenis.watergeuzen-header") }}</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">{{ $t("geschiedenis.watergeuzen") }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/watergeuzen.jpeg')"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <v-page background-color="#91B6BA" :background="require('@/assets/watermerk.svg?fill=#FFFFFF')" style="background-size: 100%;background-position:center;" class="py-16" no-auto-height>
      <v-container :fluid="$vuetify.breakpoint.mdOnly">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" lg="7">
            <v-img :src="require('@/assets/98.jpg')" max-height="700" :aspect-ratio="3/4"></v-img>
          </v-col>
          <v-col>
            <v-row>
              <v-col><h1 class="dz" style="font-size: 3rem;">{{ $t("geschiedenis.stucplafond-header") }}</h1></v-col>
            </v-row>
            <v-row>
              <v-col class="text-left black-links" v-linkified>{{ $t("geschiedenis.stucplafond") }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-page>
    <Footer></Footer>
  </div>
</template>

<script>
import VPage from "@/components/VPage";
import Footer from "@/views/Footer";

export default {
name: "Geschidenis",
  components: {VPage, Footer}
}
</script>

<style scoped lang="scss">

</style>